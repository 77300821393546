





















import Vue from "vue";
import SignupButton from "@/components/SignupButton.vue";

export default Vue.extend({
  components: {
    SignupButton,
  },
  props: {
    right: {
      type: Boolean,
    },
    feature: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
});
