














































import Vue from "vue";
import Testimonial from "@/components/Testimonial.vue";
import FeatureSection from "@/components/FeatureSection.vue";
import PreFooter from "@/components/PreFooter.vue";
import testimonials from "@/assets/data/testimonials.json";
import features from "@/assets/data/features.json";

export default Vue.extend({
  components: {
    Testimonial,
    FeatureSection,
    PreFooter,
  },
  data() {
    return {
      url: "https://boristane.com",
      testimonials,
      features,
    };
  },
  metaInfo(): { title: string } {
    return this.$route.meta;
  },
  methods: {
    createBookmark() {
      console.log("Creating a bookmark");
    },
  },
});
